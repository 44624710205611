<template>
  <div class="bottom-sheet" v-show="(product.point && company.crm) ||
    (product.hasLuckyDraw && !showQuestion) ||
    showQuestion || (product.customUrlButton && !showQuestion)
    ">
    <b-button @click="collectPoint" type="is-primary" class="is-rounded" size="is-medium" expanded
      v-show="product.point && company.crm && !isPetronas">
      <span class="flex flex-row items-center">
        <IconStar class="mr-2 fill-primary"></IconStar>
        <template v-if="product.crmNameButton != null">
          <span v-if="company.redeem" class="plausible-event-name=Click+Collect+Point">{{ product.crmNameButton
            }}</span>
          <span v-else class="plausible-event-name=Click+Lucky+Draw">{{ product.crmNameButton }}</span>
        </template>
        <template v-else>
          <span v-if="company.redeem" class="plausible-event-name=Click+Collect+Point">สะสมคะแนน</span>
          <span v-else class="plausible-event-name=Click+Lucky+Draw">คลิกเพื่อลุ้นชิงโชค</span>
        </template>
      </span>
    </b-button>

    <b-button tag="a" :href="product.luckyDrawUrl" v-show="product.hasLuckyDraw && !showQuestion" expanded
      type="is-success" class="is-rounded" :class="marginTop" size="is-medium">
      <span class="icon">
        <img src="../assets/line.png" class="mr-2" />
        <span class="plausible-event-name=Click+Add+Friend">ADD FRIEND</span>
      </span>
    </b-button>

    <b-button @click="question()" type="is-primary" outlined class="mt-2 is-rounded" size="is-medium" expanded
      v-show="showQuestion">
      <span class="flex flex-row items-center">
        <IconDoc class="mr-2 fill-primary"></IconDoc>
        <span>{{ questionTitle }}</span>
      </span>
    </b-button>

    <b-button tag="a" :href="product.customUrlButton" type="is-link" v-show="product.customUrlButton && !showQuestion"
      expanded class="is-rounded" :class="product.hasLuckyDraw || product.point ? 'mt-2' : 'mt-0'" size="is-medium">
      <span>{{ product.customNameButton }}</span>
    </b-button>
  </div>
</template>

<script>
import IconDoc from "./IconDoc.vue";
import IconStar from "./IconStar.vue";

export default {
  props: {
    product: {
      type: Object,
      default: () => { },
    },
    questionTitle: {
      type: String,
      default: "แบบสอบถาม",
    },
    useId: {
      type: Number,
      default: null,
    },
    qrcodeScanId: {
      type: Number,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
    showQuestion: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    marginTop() {
      return this.product.point ? "mt-2" : "mt-0";
    },
    lineUrl() {
      return `https://liff.line.me/${this.company.liffId}?foundCode=${this.code}&utm_source=${this.company.name}`;
    },
    siriNumberCompany() {
      return this.company.id === 161;
    },
    isPetronas() {
      return this.company.id === 176;
    },
  },
  methods: {
    question() {
      this.$router.push(
        `/${this.code}/company/${this.product.companyId}/brand/${this.product.brandId}/product/${this.product.id}/questionnaire/${this.useId}/${this.qrcodeScanId}`
      );
    },
    collectPoint() {
      const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
      if (isSamsungBrowser) {
        if (document.cookie == null || document.cookie === "") {
          this.$router.push(`/${this.code}/samsung`);
        } else {
          location.href = this.lineUrl;
        }
      } else {
        location.href = this.lineUrl;
      }
    },
  },
  components: { IconDoc, IconStar },
};
</script>

<style lang="scss" scoped></style>
